<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="productImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
  </div>
</template>

<script>
import productImg2 from "@/page/janodinero/components/img/4_2.png";
import productImg3 from "@/page/janodinero/components/img/4_3.png";
import productImg4 from "@/page/janodinero/components/img/footer.png";
export default {
  data() {
    return {
      productImg2: productImg2,
      productImg3: productImg3,
      productImg4: productImg4,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.imgBox1 {
  width: 100%;
  height: 250px;
  background-color: #f3f3f3;
}
.imgBox2,
.imgBox3 ,.imgBox4{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  margin-top: 100px;
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img3 ,.img4{
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2,
.imgItem3,.imgItem4 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  margin-top: -10px;
  margin-bottom: 100px;
}
</style>
